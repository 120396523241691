import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import './App.css';

function App() {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    const data = [
      { 
        value: [10, 8, 147, 7, 0, 5, 10, 15, 6, 5], 
        name: 'Data',
        itemStyle: { color: 'rgba(52, 152, 219, 1)' },
        areaStyle: { color: 'rgba(52, 152, 219, 0.5)' } // Fyll området med halvtransparent blå färg
      }
    ];

    const option = {
      tooltip: {},
      radar: {
        splitLine: {
          lineStyle: {
            color: ['rgb(210, 210, 210)', 'rgba(255, 0, 0, 1)', 'rgb(210, 210, 210)', 'rgba(255, 0, 0, 1)', 'rgb(210, 210, 210)'],
            width: 2,
            type: 'solid'
          }
        },
        splitArea: {
          areaStyle: {
            color: ['rgb(240, 240, 240)', 'rgba(0, 255, 0, 0.2)', 'rgba(0, 255, 0, 0.2)', 'rgb(240, 240, 240)', 'rgb(210, 210, 210)']
          }
        },
        indicator: [
          { name: 'Totalgas', min: 0, max: 20 }, // Nytt ben
          { name: 'Syre', min: 0, max: 20 },
          { name: 'Kond', max: 1000 },
          { name: 'pH', min: 3, max: 20 },
          { name: 'CO2', min: -7, max: 10 },
          { name: 'Järn', min: -7, max: 10 },
          { name: 'Koppar', min: -7, max: 10 },
          { name: 'Magnetit', min: -7, max: 10 },
          { name: 'dH', min: -35, max: 100 }
        ]
      },
      series: [
        {
          type: 'radar',
          data: data
        }
      ]
    };

    myChart.setOption(option);

    window.addEventListener('resize', () => {
      myChart.resize();
    });

    chartRef.current = myChart;

    return () => {
      myChart.dispose();
    };
  }, []);

  const handleUpdateChart = () => {
    const newData = [
      parseFloat(document.getElementById('Totalgas').value),
      parseFloat(document.getElementById('Syre').value),
      parseFloat(document.getElementById('Kond').value),
      parseFloat(document.getElementById('pH').value),
      parseFloat(document.getElementById('CO2').value),
      parseFloat(document.getElementById('Jarn').value),
      parseFloat(document.getElementById('Koppar').value),
      parseFloat(document.getElementById('Magnetit').value),
      parseFloat(document.getElementById('dH').value)
    ];

    const myChart = chartRef.current;

    const data = [
      { 
        value: newData, 
        name: 'Data',
        itemStyle: { color: 'rgba(52, 152, 219, 1)' },
        areaStyle: { color: 'rgba(52, 152, 219, 0.5)' } // Fyll området med halvtransparent blå färg
      }
    ];

    myChart.setOption({ series: [{ data }] });
  };

  return (
    <div>
      <div id="radarChart" ref={chartRef} style={{ width: '100%', height: '100vh' }}></div>
      <div id="formContainer">
        <h3>Update Data Points</h3>
        <form id="dataForm">
          <label htmlFor="Totalgas">Totalgas:</label>
          <input type="number" id="Totalgas" defaultValue="10" />

          <label htmlFor="Syre">Syre:</label>
          <input type="number" id="Syre" defaultValue="8" />

          <label htmlFor="Kond">Kond:</label>
          <input type="number" id="Kond" defaultValue="147" />

          <label htmlFor="pH">pH:</label>
          <input type="number" id="pH" defaultValue="7" />

          <label htmlFor="CO2">CO2:</label>
          <input type="number" id="CO2" defaultValue="0" />

          <label htmlFor="Jarn">Järn:</label>
          <input type="number" id="Jarn" defaultValue="5" />

          <label htmlFor="Koppar">Koppar:</label>
          <input type="number" id="Koppar" defaultValue="10" />

          <label htmlFor="Magnetit">Magnetit:</label>
          <input type="number" id="Magnetit" defaultValue="15" />

          <label htmlFor="dH">dH:</label>
          <input type="number" id="dH" defaultValue="6" />

          <button type="button" onClick={handleUpdateChart}>Update Chart</button>
        </form>
      </div>
    </div>
  );
}

export default App;
